import {
  Inject,
  Injectable,
  LOCALE_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigService } from './config.service';
import { DOCUMENT } from '@angular/common';
import { ILocaleDTO } from '../dto/config/locale.dto';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private static readonly X_DEFAULT_LOCALE = 'x-default';
  private readonly _renderer2: Renderer2;

  public constructor(
    _rendererFactory2: RendererFactory2,
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _configService: ConfigService,
    @Inject(LOCALE_ID) private readonly _localeId: string,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._renderer2 = _rendererFactory2.createRenderer(null, null);
  }

  public setCanonical(path: string): void {
    const localeDTO: ILocaleDTO = this.getCurrentLocaleDTO();
    const href = this._configService.host + localeDTO.baseHref + path;
    const head: HTMLHeadElement = this._document.head;
    let link: HTMLLinkElement | null = head.querySelector(
      `link[rel='canonical']`
    );
    if (!link) {
      link = this.createLink(head);
    }
    this._renderer2.setAttribute(link, 'rel', 'canonical');
    this._renderer2.setAttribute(link, 'href', href);
  }

  public setAlternatives(path: string): void {
    const host: string = this._configService.host;
    const head: HTMLHeadElement = this._document.head;
    const defaultLocaleDTO: ILocaleDTO = this._configService.defaultLocale;
    this.setAlternative(
      MetaService.X_DEFAULT_LOCALE,
      host + defaultLocaleDTO.baseHref + path,
      head
    );
    for (const localeDTO of this._configService.availableLocales) {
      this.setAlternative(
        localeDTO.value,
        host + localeDTO.baseHref + path,
        head
      );
    }
  }

  public setTitle(title: string): void {
    this._title.setTitle(title);
  }

  public setDescription(description: string): void {
    this.updateTag('description', description);
  }

  public setKeywords(keywords: string): void {
    this.updateTag('keywords', keywords);
  }

  private updateTag(name: string, content: string): void {
    this._meta.updateTag({ name, content });
  }

  private getCurrentLocaleDTO(): ILocaleDTO {
    return this._configService.availableLocales.find(
      (localDTO: ILocaleDTO) => this._localeId === localDTO.value
    )!;
  }

  private setAlternative(
    locale: string,
    href: string,
    head: HTMLHeadElement
  ): void {
    let link: HTMLLinkElement | null = head.querySelector(
      `link[rel='alternate'][hreflang='${locale}']`
    );
    if (!link) {
      link = this.createLink(head);
    }
    this._renderer2.setAttribute(link, 'rel', 'alternate');
    this._renderer2.setAttribute(link, 'hreflang', locale);
    this._renderer2.setAttribute(link, 'href', href);
  }

  private createLink(head: HTMLHeadElement): HTMLLinkElement {
    const link: HTMLLinkElement = this._renderer2.createElement('link');
    this._renderer2.appendChild(head, link);
    return link;
  }
}
